import React, { useLayoutEffect, useState, useEffect, useRef } from "react";
import Layout from "../../layouts/layout";
import { useMapStore } from "../../store/map";
import { Input, Textarea } from "react-daisyui";
import "../../css/upload.css";
import image from "../../images/pasimage.png";
import { toast } from "react-toastify";

import Map from "../../components/services/address/map/map";

import Captcha from "../../components/captcha/captcha";
import { urlGetListVoie, saveNewDmd, getLocaliteByCoords } from "../../api/api";
import axios from "axios";
import PopupNewAdress from "../../components/popup/popupnewadress";
import MapGenerator from "../../components/map/controls/capture/mapgeneratorimage";
import { oMap } from "../../store/omap";

import Tooltip from "../../components/tooltip/tooltip";
import mapConfig from "../../components/services/address/map/configs/map";
import { StaticImage } from "gatsby-plugin-image";

const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight - 246]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
};

interface InfoAdresse {
    nomOrg: string;
    nom: string;
    prenom: string;
    email: string;
    tel: string;
    coordx: any;
    coordy: any;
    comment: any;
    fileMap: any;
    fileImg: any;
    nomLocalite: string;
    rue: string;
}

interface InfoVoie {
    id_voie: number;
    alias: string;
}

const NewAdresse = () => {
    const {
        localite,
        coordonneex,
        coordonneey,
        setLocalite,
        setCoordonneex,
        setCoordonneey,
    } = useMapStore();

    const [profileImg, setProfilImg] = useState<any>(image);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const [reloadCaptcha, setReloadCaptcha] = useState(false);
    const mapState = useMapStore.getState();

    const initialState = {
        nomOrg: "",
        nom: "",
        prenom: "",
        email: "",
        tel: "",
        coordx: "",
        coordy: "",
        comment: "",
        nomLocalite: "",
        rue: "",
        fileMap: "",
        fileImg: "",
    };
    const [state, setState] = useState<InfoAdresse>(initialState);
    const [formErrors, setFormErrors] = useState<InfoAdresse>(initialState);
    const [nomRue, setNomRue] = useState<any>("");

    const [width, height] = useWindowSize();

    const date = new Date();

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    const currentDate = `${year}-${month}-${day}`;

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    } as Intl.DateTimeFormatOptions;

    const dateText = new Intl.DateTimeFormat("fr-FR", options).format(date);

    useEffect(() => {
        if (!(coordonneex && coordonneey)) return;

        const coordx = coordonneex;
        const coordy = coordonneey;
        setState((prevState: any) => ({
            ...prevState,
            coordx,
            coordy,
        }));
    }, [coordonneex, coordonneey]);

    useEffect(() => {
        if (!localite) return;

        const nomLocalite = localite;
        setState((prevState: any) => ({
            ...prevState,
            nomLocalite,
        }));
    }, [localite]);

    const [listeVoie, setListeVoie] = useState<InfoVoie[] | []>([]);

    const [showhide, setShowhide] = React.useState<string>("");

    const handleshowhide = (event: React.FormEvent<EventTarget>) => {
        const getuser = (event.target as HTMLInputElement).value;
        setShowhide(getuser);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;

        if (showhide === "Personne morale") {
            if (state.nomOrg) {
                document.getElementById("nomOrg")!.style.border =
                    "1px solid #D5D5D6";
            }
        }
        if (name == "nom") {
            document.getElementById("nom")!.style.border = "1px solid #D5D5D6";
        }
        if (name == "prenom") {
            document.getElementById("prenom")!.style.border =
                "1px solid #D5D5D6";
        }
        if (name == "email") {
            document.getElementById("email")!.style.border =
                "1px solid #D5D5D6";
        }
        if (name == "tel") {
            document.getElementById("tel")!.style.border = "1px solid #D5D5D6";
        }
        if (state.comment) {
            document.getElementById("comment")!.style.border =
                "1px solid #D5D5D6";
        }
        if (localite) {
            document.getElementById("localite")!.style.border =
                "1px solid #D5D5D6";
            document.getElementById("x")!.style.border = "1px solid #D5D5D6";
            document.getElementById("y")!.style.border = "1px solid #D5D5D6";
        }
        if (state.coordx) {
            document.getElementById("x")!.style.border = "1px solid #D5D5D6";
            document.getElementById("y")!.style.border = "1px solid #D5D5D6";
        }

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (showhide === "Personne morale") {
            if (state.nomOrg) {
                document.getElementById("nomOrg")!.style.border =
                    "1px solid #D5D5D6";
            }
        }
        if (state.nom) {
            document.getElementById("nom")!.style.border = "1px solid #D5D5D6";
        }
        if (state.prenom) {
            document.getElementById("prenom")!.style.border =
                "1px solid #D5D5D6";
        }
        if (state.email) {
            document.getElementById("email")!.style.border =
                "1px solid #D5D5D6";
        }
        if (state.tel) {
            document.getElementById("tel")!.style.border = "1px solid #D5D5D6";
        }
        if (state.comment) {
            document.getElementById("comment")!.style.border =
                "1px solid #D5D5D6";
        }
        if (localite) {
            document.getElementById("localite")!.style.border =
                "1px solid #D5D5D6";
            document.getElementById("x")!.style.border = "1px solid #D5D5D6";
            document.getElementById("y")!.style.border = "1px solid #D5D5D6";
        }
        if (state.coordx) {
            document.getElementById("x")!.style.border = "1px solid #D5D5D6";
            document.getElementById("y")!.style.border = "1px solid #D5D5D6";
        }
        if (profileImg != image) {
            document.getElementById("img")!.style.border = "1px solid #D5D5D6";
        }
    }, [state, profileImg]);

    const onChangeRue = (e: any) => {
        const rue = e.target.value;
        setNomRue(rue);

        setState((prevState) => ({
            ...prevState,
            rue,
        }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setFormErrors(validate(state));

        if (localite && state.coordx == "" && state.comment == "") {
            toast.error(
                "La localité n'est pas renseignée via la carte, veuillez donner plus d'informations dans le champ commentaire.",
                {
                    position: "top-right",
                    autoClose: 4000,
                }
            );
        }

        if (!isCaptchaValid) {
            toast.error("Valeur captcha incorrecte, veuillez rééssayer!", {
                position: "top-right",
                autoClose: 4000,
            });
            setReloadCaptcha(true);
        }
    };

    const validate = (values: any) => {
        const errors = initialState;
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (showhide === "Personne morale") {
            if (!values.nomOrg) {
                document.getElementById("nomOrg")!.style.border =
                    "1px solid red";
            }
        }
        if (!values.nom) {
            document.getElementById("nom")!.style.border = "1px solid red";
        }
        if (!values.prenom) {
            document.getElementById("prenom")!.style.border = "1px solid red";
        }
        if (!values.email) {
            document.getElementById("email")!.style.border = "1px solid red";
        } else if (!regex.test(values.email)) {
            errors.email = "Email invalide!";
        }
        if (!values.tel) {
            document.getElementById("tel")!.style.border = "1px solid red";
        }
        if (!localite) {
            document.getElementById("x")!.style.border = "1px solid red";
            document.getElementById("y")!.style.border = "1px solid red";
            document.getElementById("localite")!.style.border = "1px solid red";
            document.getElementById("comment")!.style.border = "1px solid red";
        } else {
            document.getElementById("x")!.style.border = "1px solid #D5D5D6";
            document.getElementById("y")!.style.border = "1px solid #D5D5D6";
            document.getElementById("localite")!.style.border =
                "1px solid #D5D5D6";
            if (!values.coordx && !values.comment) {
                document.getElementById("comment")!.style.border =
                    "1px solid red";
            } else {
                document.getElementById("comment")!.style.border =
                    "1px solid #D5D5D6";
            }
        }
        if (profileImg == image) {
            document.getElementById("img")!.style.border = "1px solid red";
            toast.error(
                "Votre demande est incomplète et ne peut être enregistrée car vous n’avez pas joint de photo de votre entrée",
                {
                    position: "top-right",
                    autoClose: 4000,
                }
            );
        }

        return errors;
    };

    useEffect(() => {
        if (!(coordonneex && coordonneey)) return;

        const x = coordonneex;
        const y = coordonneey;
        axios
            .get(urlGetListVoie(x, y, 20))
            .then((response) => {
                let data = response.data;
                let dataSuccess = [];

                if (data.success !== false) {
                    dataSuccess = data;
                }

                setListeVoie(dataSuccess);
            })
            .catch((error) => {
                console.log(error);
            });

        getLocaliteByCoords(x, y)
            .then((response) => {
                let data = response.data;
                let dataSuccess = [];

                if (data.success !== false) {
                    dataSuccess = data;
                    setLocalite(dataSuccess.nom);
                } else {
                    setLocalite("");
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [coordonneex, coordonneey]);

    const ref: any = useRef(null);

    if (width == 0 || height == 0) return null;

    const imageHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfilImg(reader.result);
            }
        };
        if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const retourPage = (e: any) => {
        e.preventDefault();
        history.back();
    };

    const afficherPosition = (e: any) => {
        e.preventDefault();

        // const long = "-4.008714793686863";
        // const lat = "5.327402730871825";

        navigator.geolocation.getCurrentPosition(
            (position) => {
                const bounds = mapConfig.bounds_all_districts;
                if (
                    bounds[0] < position.coords.longitude &&
                    position.coords.longitude < bounds[2] &&
                    bounds[1] < position.coords.latitude &&
                    position.coords.latitude < bounds[3]
                ) {
                    const long = position.coords.longitude.toString();
                    const lat = position.coords.latitude.toString();

                    setCoordonneey(lat);
                    setCoordonneex(long);
                    getLocaliteByCoords(long, lat)
                        .then((response) => {
                            let data = response.data;
                            let dataSuccess = [];

                            if (data.success) {
                                dataSuccess = data;
                                setLocalite(dataSuccess.nom);
                            } else {
                                setLocalite("");
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    toast.info(
                        `Vous-êtes hors des limites de la carte, votre position actuelle : Longitude = ${position.coords.longitude}, Latitude = ${position.coords.latitude}`,
                        {
                            position: "top-right",
                            autoClose: 4000,
                        }
                    );
                }
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    toast.warning(
                        "Veuillez activer la géolocalisation de votre appareil pour accéder à cette fonctionnalité.",
                        {
                            position: "top-right",
                            autoClose: 4000,
                        }
                    );
                } else {
                    toast.error(
                        "Erreur lors de la récupération de la position.",
                        {
                            position: "top-right",
                            autoClose: 4000,
                        }
                    );
                }
            }
        );
    };

    const handleCaptchaValidation = (valid: any) => {
        setIsCaptchaValid(valid);
    };

    const showModal = (e: any) => {
        e.preventDefault();

        const fileImg = profileImg;

        setState((prev) => ({
            ...prev,
            fileImg,
        }));

        if (localite) {
            if (!state.coordx) {
                if (
                    showhide === "Personne morale"
                        ? !state.nomOrg ||
                          !state.nom ||
                          !state.prenom ||
                          !state.email ||
                          formErrors.email == "Email invalide!" ||
                          !state.tel ||
                          !state.comment ||
                          profileImg == image ||
                          !isCaptchaValid
                        : !state.nom ||
                          !state.prenom ||
                          !state.email ||
                          formErrors.email == "Email invalide!" ||
                          !state.tel ||
                          !state.comment ||
                          profileImg == image ||
                          !isCaptchaValid
                ) {
                    handleSubmit(e);
                } else {
                    setReloadCaptcha(true);
                    handleSubmit(e);
                    setModalShow(true);
                    generateCaptureMap(coordonneex, coordonneey);
                }
            } else {
                if (
                    showhide === "Personne morale"
                        ? !state.nomOrg ||
                          !state.nom ||
                          !state.prenom ||
                          !state.email ||
                          formErrors.email == "Email invalide!" ||
                          !state.tel ||
                          profileImg == image ||
                          !isCaptchaValid
                        : !state.nom ||
                          !state.prenom ||
                          !state.email ||
                          formErrors.email == "Email invalide!" ||
                          !state.tel ||
                          profileImg == image ||
                          !isCaptchaValid
                ) {
                    handleSubmit(e);
                } else {
                    setReloadCaptcha(true);
                    handleSubmit(e);
                    setModalShow(true);
                    generateCaptureMap(coordonneex, coordonneey);
                }
            }
        } else {
            if (
                showhide === "Personne morale"
                    ? !state.nomOrg ||
                      !state.nom ||
                      !state.prenom ||
                      !state.email ||
                      formErrors.email == "Email invalide!" ||
                      !state.tel ||
                      !localite ||
                      profileImg == image
                    : !state.nom ||
                      !state.prenom ||
                      !state.email ||
                      formErrors.email == "Email invalide!" ||
                      !state.tel ||
                      !localite ||
                      profileImg == image ||
                      !isCaptchaValid
            ) {
                handleSubmit(e);
            } else {
                setReloadCaptcha(true);
                handleSubmit(e);
                setModalShow(true);
                generateCaptureMap(coordonneex, coordonneey);
            }
        }

        setTimeout(() => {
            setReloadCaptcha(false);
        }, 1000);
    };

    const divStyles = {
        boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
        marginTop: "-6px",
        height: height / 1.15 + "px",
    };
    const divPorte = {
        boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
    };

    const resetImage = (e: any) => {
        e.target.value = null;
        setProfilImg(image);
    };

    const handleSave = (e: any) => {
        e.preventDefault();

        const imgCap = mapState.imgCapture;

        const imgDoorString = state.fileImg.replace(/.*;base64,/, "");
        const imgDMapString = imgCap.replace(/.*;base64,/, "");

        setIsLoading(true);

        saveNewDmd({
            organisme: state.nomOrg,
            nom: state.nom,
            prenom: state.prenom,
            email: state.email,
            telephone: state.tel,
            rue: state.rue,
            x: state.coordx,
            y: state.coordy,
            commentaire: state.comment,
            map_file: imgDMapString,
            door_file: imgDoorString,
            date: currentDate,
            nomLocalite: nomLocalite,
        })
            .then((res) => {
                let data = res.data;
                if (data.success) {
                    toast.info(
                        `L'enregistrement a été effectué avec succès sous le N° ${data.numero_demande}.`,
                        {
                            position: "top-right",
                            autoClose: 4000,
                        }
                    );

                    resetImage(e);
                    setModalShow(false);
                    setCoordonneey("");
                    setCoordonneex("");
                    setLocalite("");
                    setState(initialState);
                } else if (data.msg == "limit") {
                    toast.error(
                        "Votre demande ne peut pas être enregistrée car vous avez déjà fait une demande que nous avons prise en compte",
                        {
                            position: "top-right",
                            autoClose: 4000,
                        }
                    );

                    resetImage(e);
                    setModalShow(false);
                    setCoordonneey("");
                    setCoordonneex("");
                    setLocalite("");
                    setState(initialState);
                } else {
                    toast.error("L'enregistrement n'a pas abouti !", {
                        position: "top-right",
                        autoClose: 4000,
                    });

                    console.log("No data found : " + data);
                }
                setIsLoading(false);
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const { nomLocalite, rue } = state;

    const generateCaptureMap = (lng: any, lat: any) => {
        const mapObj = oMap.getState().omap;

        const mapGenerator = new MapGenerator(mapObj!);
        const fileMap = mapGenerator.generate(lng, lat);

        console.log(fileMap);

        setState((prev) => ({
            ...prev,
            fileMap,
        }));
    };

    const handleLocaliteChange = (event: any) => {
        setLocalite(event.target.value);
    };

    const voieSelectionne = listeVoie.filter(
        (option) => option.id_voie == nomRue
    );

    const style = {
        height: "auto",
        width: "auto",
    };

    return (
        <Layout>
            <div className={`tw-justify-center tw-items-center`} style={style}>
                <h4 className="tw-flex tw-justify-center tw-items-center tw-text-center tw-text-2xl tw-pt-5 tw-pb-5">
                    Formulaire de demande de nouvelle adresse
                </h4>
                <div
                    className={`tw-flex tw-justify-center tw-items-center tw-py-3`}
                    style={style}
                >
                    <div className="tw-flex tw-justify-around tw-flex-wrap tw-space-x-2 tw-w-full tw-px-8 lg:tw-px-20 tw-pb-10 lg:tw-pb-0">
                        <form className="tw-flex tw-flex-col tw-space-y-2 tw-items-center tw-w-full lg:tw-w-[30%] tw-pb-5 lg:tw-pb-0">
                            <div className="tw-flex tw-w-[100%] tw-justify-center tw-pt-2">
                                <label className="tw-text-xl tw-text-center tw-font-bold tw-pt-2 tw-pl-6">
                                    Demande de nouvelle adresse
                                </label>
                                <Tooltip
                                    content="Afficher ma position"
                                    direction="bottom-center"
                                >
                                    <button
                                        onClick={(e) => afficherPosition(e)}
                                        className="tw-mx-5 tw-btn tw-rounded-xl tw-text-white tw-bg-slate-300 hover:tw-bg-slate-500"
                                    >
                                        <StaticImage
                                            src="../../icons/pictos/cible.svg"
                                            alt="cible"
                                            style={{ width: 30, height: 30 }}
                                        />
                                    </button>
                                </Tooltip>
                            </div>
                            <div className="tw-flex tw-space-x-2 tw-w-full tw-max-w-xl">
                                <select
                                    name="type_personne"
                                    defaultValue={"Personne physique"}
                                    onChange={(e) => handleshowhide(e)}
                                    className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                >
                                    <option value="Personne physique">
                                        Personne physique
                                    </option>
                                    <option value="Personne morale">
                                        Personne morale
                                    </option>
                                </select>
                                <div className="tw-w-full tw-max-w-xs">
                                    <Input
                                        className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                        placeholder="Date"
                                        value={dateText}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            {showhide === "Personne morale" ? (
                                <>
                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Nom de l'organisme"
                                            onChange={handleChange}
                                            value={state.nomOrg}
                                            name="nomOrg"
                                            id="nomOrg"
                                        />
                                        <p
                                            className={`tw-text-[#FF0303] tw-text-xs `}
                                        >
                                            {formErrors.nomOrg}
                                        </p>
                                    </div>
                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Nom du représentant"
                                            onChange={handleChange}
                                            value={state.nom}
                                            name="nom"
                                            id="nom"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.nom}
                                        </p>
                                    </div>
                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Prénom du représentant"
                                            onChange={handleChange}
                                            value={state.prenom}
                                            name="prenom"
                                            id="prenom"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.prenom}
                                        </p>
                                    </div>
                                    <div className="tw-flex tw-space-x-2 tw-w-full tw-max-w-xl">
                                        <div className="tw-w-full tw-max-w-xs">
                                            <Input
                                                className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                                placeholder="Email"
                                                onChange={handleChange}
                                                value={state.email}
                                                name="email"
                                                id="email"
                                            />
                                            <p className="tw-text-[#FF0303] tw-text-xs">
                                                {formErrors.email}
                                            </p>
                                        </div>
                                        <div className="tw-w-full tw-max-w-xs">
                                            <Input
                                                className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                                placeholder="Télephone"
                                                onChange={handleChange}
                                                value={state.tel}
                                                name="tel"
                                                id="tel"
                                            />
                                            <p className="tw-text-[#FF0303] tw-text-xs">
                                                {formErrors.tel}
                                            </p>
                                        </div>
                                    </div>
                                    <select
                                        value={nomRue}
                                        onChange={(e) => onChangeRue(e)}
                                        className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                    >
                                        <option value="">
                                            --Choisir une rue--
                                        </option>
                                        <option value="voie non dénommée">
                                            Voie non dénommée
                                        </option>
                                        {listeVoie.map((voie) => (
                                            <option
                                                key={voie.id_voie}
                                                value={voie.id_voie}
                                                selected={nomRue === rue}
                                            >
                                                {voie.alias}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Localité"
                                            value={localite!}
                                            id="localite"
                                            onChange={handleLocaliteChange}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Nom"
                                            onChange={handleChange}
                                            value={state.nom}
                                            id="nom"
                                            name="nom"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.nom}
                                        </p>
                                    </div>
                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Prénom"
                                            onChange={handleChange}
                                            value={state.prenom}
                                            id="prenom"
                                            name="prenom"
                                        />
                                        <p className="tw-text-[#FF0303] tw-text-xs">
                                            {formErrors.prenom}
                                        </p>
                                    </div>

                                    <div className="tw-flex tw-space-x-2 tw-w-full tw-max-w-xl">
                                        <div className="tw-w-full tw-max-w-xs">
                                            <Input
                                                className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                                placeholder="Email"
                                                onChange={handleChange}
                                                value={state.email}
                                                id="email"
                                                name="email"
                                            />
                                            <p className="tw-text-[#FF0303] tw-text-xs">
                                                {formErrors.email}
                                            </p>
                                        </div>
                                        <div className="tw-w-full tw-max-w-xs">
                                            <Input
                                                className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                                placeholder="Télephone"
                                                onChange={handleChange}
                                                value={state.tel}
                                                id="tel"
                                                name="tel"
                                            />
                                            <p className="tw-text-[#FF0303] tw-text-xs">
                                                {formErrors.tel}
                                            </p>
                                        </div>
                                    </div>
                                    <select
                                        value={nomRue}
                                        onChange={(e) => onChangeRue(e)}
                                        className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                    >
                                        <option value="">
                                            --Choisir une rue--
                                        </option>
                                        <option value="voie non dénommée">
                                            Voie non dénommée
                                        </option>
                                        {listeVoie.map((voie) => (
                                            <option
                                                key={voie.id_voie}
                                                value={voie.id_voie}
                                                selected={nomRue === rue}
                                            >
                                                {voie.alias}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="tw-w-full tw-max-w-xl">
                                        <Input
                                            className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                            placeholder="Localité"
                                            value={localite!}
                                            id="localite"
                                            onChange={handleLocaliteChange}
                                        />
                                    </div>
                                </>
                            )}
                            {coordonneey ? (
                                <></>
                            ) : (
                                <label className="tw-text-center tw-italic">
                                    Cliquer sur la carte pour afficher les
                                    coordonnées <br /> dans le formulaire
                                </label>
                            )}
                            <div className="tw-flex tw-space-x-2 tw-w-full tw-max-w-xl">
                                <div className="tw-w-full tw-max-w-xs">
                                    <Input
                                        className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                        placeholder="X"
                                        value={
                                            coordonneex
                                                ? coordonneex
                                                : state.coordx
                                        }
                                        onChange={handleChange}
                                        disabled={true}
                                        id="x"
                                        name="x"
                                    />
                                    <p className="tw-text-[#FF0303] tw-text-xs">
                                        {formErrors.coordx}
                                    </p>
                                </div>
                                <div className="tw-w-full tw-max-w-xs">
                                    <Input
                                        className="tw-pl-3 tw-border-[1px] tw-text-[#808080] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                        placeholder="Y"
                                        value={
                                            coordonneey
                                                ? coordonneey
                                                : state.coordy
                                        }
                                        onChange={handleChange}
                                        disabled={true}
                                        id="y"
                                        name="y"
                                    />
                                    <p className="tw-text-[#FF0303] tw-text-xs">
                                        {formErrors.coordy}
                                    </p>
                                </div>
                            </div>

                            <div className="tw-w-full tw-max-w-xl">
                                <Textarea
                                    className="tw-pl-3 tw-border-[1px] tw-text-[#000] tw-border-[#D7D7D7] tw-h-[36px] tw-rounded-[4px] tw-w-full tw-max-w-xl focus:tw-outline-[#2684FF] "
                                    placeholder="Commentaire"
                                    onChange={handleChange}
                                    name="comment"
                                    id="comment"
                                />
                                <p className="tw-text-[#FF0303] tw-text-xs">
                                    {formErrors.comment}
                                </p>
                            </div>

                            <Captcha
                                onCaptchaValidation={handleCaptchaValidation}
                                reload={reloadCaptcha}
                            />

                            <div className="tw-flex tw-justify-around tw-w-full tw-max-w-sm tw-mt-2 tw-space-x-2">
                                <button
                                    onClick={(e) => retourPage(e)}
                                    className="tw-btn tw-btn-sm tw-max-w-xs tw-font-normal tw-w-[47%] tw-rounded-xl tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                                >
                                    Annuler
                                </button>
                                <button
                                    onClick={(e) => showModal(e)}
                                    className="tw-btn tw-btn-sm tw-max-w-xs tw-font-normal tw-w-[47%] tw-rounded-xl tw-text-white tw-bg-secondary hover:tw-bg-secondary_hover"
                                >
                                    Enregistrer
                                </button>
                            </div>
                            <div className="tw-pt-4">
                                <span>
                                    La géolocalisation qui est demandée pour
                                    votre nouvelle adresse doit correspondre à
                                    l'entrée principale de votre construction.
                                    Il convient donc de bien se placer devant
                                    cette entrée face à la voie attenante pour
                                    localiser le mieux possible le point.
                                </span>
                            </div>
                        </form>

                        <div className="page tw-w-96 tw-max-w-xl tw-h-auto">
                            <div className="container tw-h-full">
                                <div className="img-holder">
                                    <img
                                        src={profileImg}
                                        alt=""
                                        id="img"
                                        className="img"
                                    />
                                </div>
                                <input
                                    type="file"
                                    accept="image/*"
                                    name="image-upload"
                                    id="input"
                                    onChange={imageHandler}
                                    ref={ref}
                                />
                                <div className="tw-mt-10">
                                    <div className="label">
                                        <label
                                            className="image-upload tw-text-base"
                                            htmlFor="input"
                                        >
                                            Photo de votre porte
                                        </label>
                                    </div>
                                    <div className="label">
                                        <label
                                            className="image-upload tw-text-base"
                                            onClick={(e) => resetImage(e)}
                                        >
                                            Annuler
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" tw-w-full lg:tw-mt-[3px] lg:tw-w-[40%]">
                            <div className="tw-mb-3 tw-text-center">
                                <p className="tw-font-bold">
                                    Carte de localisation
                                </p>
                            </div>
                            <div
                                style={divStyles}
                                className="tw-flex tw-w-[100%] tw-rounded-md tw-items-center"
                            >
                                <Map bNewDemande={true} />
                            </div>
                        </div>
                    </div>
                </div>

                <PopupNewAdress
                    show={modalShow}
                    onHide={() => {
                        setModalShow(false);
                        mapState.setImgCapture("");
                    }}
                    handleSave={handleSave}
                    isLoading={isLoading}
                    {...state}
                    voieSelectionne={voieSelectionne}
                    currentDate={dateText}
                />
            </div>
        </Layout>
    );
};

export default NewAdresse;
