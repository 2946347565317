import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { useMapStore } from "../../store/map";

import MoonLoader from "react-spinners/MoonLoader";

interface MyPopup {
    show: boolean;
    onHide: any;
    handleSave: any;
    nom: string;
    prenom: string;
    email: string;
    tel: string;
    nomLocalite: string;
    coordx: number;
    coordy: number;
    fileImg: any;
    nomOrg?: string;
    rue: string;
    voieSelectionne: any[];
    comment: string;
    currentDate: string;
    isLoading: boolean;
}

const PopupNewAdress: React.FC<MyPopup> = ({
    onHide,
    show,
    handleSave,
    nom,
    prenom,
    email,
    tel,
    nomLocalite,
    coordx,
    coordy,
    fileImg,
    nomOrg,
    rue,
    voieSelectionne,
    comment,
    currentDate,
    isLoading,
}) => {
    const mapState = useMapStore.getState();

    const imgCap = mapState.imgCapture;

    let nom_rue: string = "";
    if (voieSelectionne.length > 0) {
        nom_rue = voieSelectionne[0].alias;
    }
    rue = nom_rue == "" ? "Voie non dénommée" : nom_rue;

    return (
        <Modal
            className="tw-relative"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            size="xl"
        >
            {isLoading && (
                <div className="tw-absolute tw-flex tw-justify-center tw-items-center tw-h-full tw-w-full tw-left-0 tw-bg-white tw-opacity-50 tw-z-50">
                    <MoonLoader color="#21254C" size={150} />
                </div>
            )}
            <Modal.Header closeButton onClick={onHide}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Récapitulatif de la demande du {currentDate}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Informations</h4>
                <Table
                    striped
                    bordered
                    hover
                    size="lg"
                    style={{ fontSize: 12 }}
                    responsive
                >
                    <thead>
                        <tr>
                            {nomOrg && <th>Organisme</th>}
                            <th>Nom</th>
                            <th>Prénom</th>
                            <th>E-mail</th>
                            <th>Télephone</th>
                            <th>Rue</th>
                            <th>X</th>
                            <th>Y</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {nomOrg && <td>{nomOrg}</td>}
                            <td>{nom}</td>
                            <td>{prenom}</td>
                            <td>{email}</td>
                            <td>{tel}</td>
                            <td>{rue}</td>
                            <td>{coordx}</td>
                            <td>{coordy}</td>
                        </tr>
                    </tbody>
                </Table>
                <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-around tw-mb-3">
                    <img src={fileImg} style={{ width: 472, height: 333 }} />

                    <img src={imgCap} style={{ width: 472, height: 333 }} />
                </div>
                <Table
                    striped
                    bordered
                    hover
                    size="lg"
                    style={{ fontSize: 12 }}
                    responsive
                >
                    <thead>
                        <tr>
                            <th style={{ fontSize: 16 }}>Localité</th>
                            <th style={{ fontSize: 16 }}>Commentaire</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ fontSize: 13 }}>{nomLocalite}</td>
                            <td style={{ fontSize: 13 }}>{comment}</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={(e) => {
                        handleSave(e);
                        //handleVideImgMap();
                    }}
                    style={{ background: "#ED6E19" }}
                >
                    Valider
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PopupNewAdress;
