import React, {useState} from 'react'

const Tooltip = (props:any) => {
    let timeout: any;
    const [active, setActive] = useState<boolean>(false);
  
    const showTip = () => {
      timeout = setTimeout(() => {
        setActive(true);
      }, props.delay || 500);
    };
  
    const hideTip = () => {
      clearInterval(timeout);
      setActive(false);
    };
  
    return (
      <div
        className="Tooltip-Wrapper"
        // When to show the tooltip
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
      >
        {/* Wrapping */}
        {props.children}
        {active && (
          <div className={`Tooltip-Tip ${props.direction || "top"}`}>
            {/* Content */}
            {props.content}
          </div>
        )}
      </div>
    );
}

export default Tooltip
